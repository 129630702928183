/** @format */

import { TAction } from '../../redux/declarations';
import { TStore } from '../../redux/helpers/createStore';
import { DEFAULT_CHECK_IN_DATE, DEFAULT_ORDER_BY, DEFAULT_PRICE_MAX, DEFAULT_PRICE_MIN } from './constants';

// state filter defaults do not edit
const INITIAL_STATE = {
  area: {},
  checkInDate: DEFAULT_CHECK_IN_DATE,
  singleRoom: false,
  entireStudio: false,
  sharedApartment: false,
  colivingBuilding: false,
  orderBy: DEFAULT_ORDER_BY,
  monthlyPrice: {
    min: DEFAULT_PRICE_MIN,
    max: DEFAULT_PRICE_MAX,
  },
  concierge: false,
  elevator: false,
  privateBathroom: false,
  balcony: false,
  bikeParking: false,
  topFloor: false,
  lowerFloor: false,
  parquet: false,
  clothesDryer: false,
  airConditioning: false,
  internalSide: false,
  gym: false,
  inSuiteLaundry: false,
} as const;

// eslint-disable-next-line @typescript-eslint/default-param-last
export const reducerFilters = (prevState = INITIAL_STATE, action: TAction): TStore['filters'] => {
  switch (action.type) {
    case 'SEARCH_ADDRESS_SET':
      return { ...prevState, address: action.payload };
    case 'RESET_SEARCH_AREA':
      return { ...prevState, area: {} };
    case 'RESET_SEARCH_PARAMS':
      return INITIAL_STATE;
    default:
      return prevState;
  }
};
