/** @format */

import { TReducerGeneral } from '@/@ducks/general/selectors';
import { TAction } from '@/redux/declarations';

export const reducerGeneral = (
  prevState = {
    GTMListType: ['sorting_by_product_filters'],
  } as TReducerGeneral,
  action: TAction,
): TReducerGeneral => {
  let newState;
  switch (action.type) {
    case 'SET_CURRENT_PAGE':
      return { ...prevState, currentPage: action.payload };
    case 'SET_LOCALE':
      return {
        ...prevState,
        locale: action.payload,
      };
    case 'GET_BOOKING_COMMERCIAL_CONDITIONS_REQUEST':
    case 'GET_BOOKING_CONTRACTUAL_CONDITIONS_REQUEST':
      return { ...prevState, isLoadingConditions: true };
    case 'GET_BOOKING_CONTRACTUAL_CONDITIONS_SUCCESS':
    case 'GET_BOOKING_CONTRACTUAL_CONDITIONS_FAILURE':
    case 'GET_BOOKING_COMMERCIAL_CONDITIONS_SUCCESS':
    case 'GET_BOOKING_COMMERCIAL_CONDITIONS_FAILURE':
      newState = { ...prevState };
      delete newState.isLoadingConditions;
      return newState;
    case 'RESET_VIEW_MAP':
      return { ...prevState, isViewMap: false };
    case 'SET_GTM_LIST_TYPE':
      prevState.GTMListType ? prevState.GTMListType.push(action.payload) : null;
      return { ...prevState };
    case 'REMOVE_GTM_LIST_TYPE':
      newState = { ...prevState };
      if (action.payload === 'view_on_map') {
        const filteredValue = newState.GTMListType && newState.GTMListType.filter(el => el !== 'view_on_map');
        return { ...newState, GTMListType: filteredValue };
      } else newState.GTMListType && newState.GTMListType.pop();
      return { ...newState };
    default:
      return prevState;
  }
};
