/** @format */

import {
  BookingApplicationForm,
  BookingContactDetails,
  BookingFinancialValidation,
  BookingOptions,
  BookingPersonalDetails,
  BookingResidentialAddress,
  BookingSecondOccupant,
  SecondOccupantOptions,
  TReducerBooking,
} from '@/ducks/bookingNew/types';

export const DEFAULT_BOOKING_PERSONAL_DETAILS: BookingPersonalDetails = {
  sex: undefined,
  cityOfBirth: '',
  countryOfBirth: '',
  dateOfBirth: '',
  nationality: '',
  taxCode: '',
  occupation: undefined,
  preferredLanguage: undefined,
};

export const DEFAULT_BOOKING_CONTACT_DETAILS: BookingContactDetails = {
  email: '',
  firstName: '',
  lastName: '',
  middleName: '',
  phoneNumber: '',
  privacyPolicy: false,
  marketingAccepted: false,
};

export const DEFAULT_BOOKING_SECOND_OCCUPANT: BookingSecondOccupant = {
  secondOccupant: SecondOccupantOptions.NO,
};

export const DEFAULT_BOOKING_RESIDENTIAL_ADDRESS: BookingResidentialAddress = {
  country: '',
  province: '',
  city: '',
  zipCode: '',
  address: '',
  houseNumber: '',
};

export const DEFAULT_BOOKING_FINANCIAL_VALIDATION: BookingFinancialValidation = {
  provider: null,
};

export const DEFAULT_BOOKING_APPLICATION: BookingApplicationForm = {
  checkInDate: '',
  checkOutDate: '',
  contactDetails: DEFAULT_BOOKING_CONTACT_DETAILS,
  contactDetailsErrors: false,
  personalDetails: DEFAULT_BOOKING_PERSONAL_DETAILS,
  personalDetailsErrors: false,
  secondOccupant: DEFAULT_BOOKING_SECOND_OCCUPANT,
  secondOccupantErrors: false,
  residentialAddress: DEFAULT_BOOKING_RESIDENTIAL_ADDRESS,
  residentialAddressErrors: false,
  financialValidation: DEFAULT_BOOKING_FINANCIAL_VALIDATION,
  financialValidationErrors: false,
  howDidYouFindUs: '',
  howDidYouFindUsErrors: false,
};

export const DEFAULT_BOOKING_OPTIONS: BookingOptions = {
  countries: [],
  prefixes: [],
  provinces: [],
  genders: [],
  nationalities: [],
  professions: [],
};

export const DEFAULT_BOOKING: TReducerBooking = {
  applicationForm: DEFAULT_BOOKING_APPLICATION,
  availability: undefined,
  room: undefined,
  bookingOptions: DEFAULT_BOOKING_OPTIONS,
  commercialConditions: undefined,
  contractualConditions: undefined,
  loaders: [],
  error: undefined,
  step: 'contacts',
  paymentInfo: undefined,
  errors: {},
  couponCode: {
    code: undefined,
    discountAmount: undefined,
  },
};
